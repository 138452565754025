<template>
  <div ref="root" class="delay-wrapper">
    <template v-if="!blockRender || visible">
      <slot/>
    </template>
  </div>
</template>

<script>
import delayLoad from '@/mixins/delayLoad';

export default {
  name: 'delay-load',
  mixins: [ delayLoad, ],
  props: {
    blockRender: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onVisible() {
      this.$emit('visible');
    },
  },
};
</script>

<style lang="scss" scoped>
.delay-wrapper {
  width: 100%;
}
</style>