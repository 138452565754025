<template>
  <base-styles>
    <div
      ref="root"
      class="martech-image"
      :class="{'is-flip-button' : flipWithNoIcon}"
      :style="[{width: cardDimensions.width}, {height: cardDimensions.height}]"
      :data-testid="componentID()">
      <div class="card-image" :class="[{'transform-backside' : isTransformCard}, {'flip-180-rotate' : isFlipCard}]">
        <div class="card-image--front" :class="{'product-image' : isProduct}">
          <img v-if="hasRoot"
               :src="imageURL(imageUrl, { maxDimensions: 'autoxauto', quality: 75, })"
               :alt="imageAltText"
               :class="{'card-img': !isProduct}"
               :style="[{width: cardDimensions.width}, {height: cardDimensions.height}]"
               @error="errorImageFallback($event)">
        </div>
        <div v-show="isTransformCard" class="card-image--back" :class="{'is-hidden' : !isTransformCard}">
          <img v-if="hasRoot"
               :src="imageURL(imageUrl, { maxDimensions: 'autoxauto', quality: 75, })"
               :alt="imageAltText"
               :class="{'card-img': !isProduct}"
               :style="[{width: cardDimensions.width}, {height: cardDimensions.height}]"
               @error="errorImageFallback($event)">
        </div>
      </div>
      <div v-show="loaded" class="martech-image__button">
        <flip-button v-if="showFlipButton" :is-icon="flipIsIcon" @flip="$emit('flip')"/>
        <magnify-button v-if="showMagnifyButton" @magnify="$emit('magnify')" :card-name="cardData.name"/>
      </div>
    </div>
  </base-styles>
</template>

<script>
import delve from 'dlv';
import FlipButton from '@/components/elements/FlipButton.vue';
import MagnifyButton from '@/components/elements/MagnifyButton.vue';
import imageAlter from '@/mixins/image/alter';
import image from '@/lib/image';
import BaseStyles from '@/components/BaseStyles.vue';
import componentId from '@/mixins/componentId';
import deviceType from '@/mixins/deviceType';

export default {
  name: 'martech-image',
  components: {
    FlipButton,
    MagnifyButton,
    BaseStyles,
  },
  mixins: [ imageAlter, deviceType ],
  props: {
    cardData: {
      type: Object,
      default: () => ({
        name: '', flipImageURL: '', imageURL: '', layout: '',
      }),
    },
    productImageUrl: {
      type: String,
      required: false,
      default: false,
    },
    productImageAltText: {
      type: String,
      required: false,
      default: false,
    },
    isProduct: {
      type: Boolean,
      default: false,
    },
    isFlipped: {
      type: Boolean,
      default: false,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    allowMagnify: {
      type: Boolean,
      default: false,
    },
    allowFlip: {
      type: Boolean,
      default: false,
    },
    flipIsIcon: {
      type: Boolean,
      default: true,
    },
    cardImageFallback: {
      type: String,
      default: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/ghost_imageFallbackPlaceholder%402x.png',
    },
    cardDimensions: {
      type: Object,
      default: () => ({
        width: '200px',
        height: '280px',
      }),
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    showMagnifyButton() {
      return this.deviceType === 'desktop' && this.allowMagnify && !this.error;
    },
    showFlipButton() {
      return this.allowFlip && !this.error
        && delve(this, 'cardData.layout', '').match(/transform|modal_dfc|flip|dfc/);
    },
    isTransformCard() {
      return this.isFlipped && delve(this, 'cardData.layout', '').match(/transform|modal_dfc/);
    },
    isFlipCard() {
      return this.isFlipped && delve(this, 'cardData.layout', '').match(/flip/);
    },
    flipWithNoIcon() {
      return this.showFlipButton && !this.flipIsIcon;
    },
    imageUrl() {
      // This is used if the card is a split card and needs to rotate 180 degrees when flipped.
      // These cards do not use flipImageURL or they break and display ghosty mc ghostface
      if (this.isFlipCard) {
        return this.cardData.imageURL;
      }

      // This returns a card image based on tcgPlayerID and will create the image url.
      if (this.cardData.tcgPlayerID) {
        return image.getCardImage(this.cardData.tcgPlayerID, this.isFlipped);
      }

      const prodImageURL =  this.productImageUrl || this.cardData.imageURL;

      // This returns the proper front or back of a transform or modal_dfc card if isFlipped is true.
      return this.isFlipped ? this.cardData.flipImageURL : prodImageURL;
    },
    imageAltText() {
      return this.productImageAltText ? this.productImageAltText : `${this.cardData.name} - ${this.cardData.setName} - ${this.cardData.game}`;
    },
  },
  methods: {
    errorImageFallback(event) {
      this.error = true;
      // Guard against infinite loop
      const fallback = image.getFallbackImage(null, 'card-fallback-image')
      if (!event?.target || event.target.src === fallback) return;
      event.target.src = fallback;
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-image {
  position: relative;

  &.is-flip-button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__button {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: $martech-z-index-10;

    :deep(.martech-flip-buttons) {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    :deep(.martech-magnify-button) {
      position: absolute;
      top: -175px;
      left: 0;
      height: 48px;
      width: 48px;
    }
  }
}

.card-image {
  transform-style: preserve-3d;
  transition: transform 200ms;
  position: relative;
  overflow: visible;
  z-index: $martech-z-index-10;
  height: 100%;
  width: 100%;

  &.transform-backside {
    transition: transform 600ms;
    transform: rotateY(-180deg);

    .card-image {
      &--back {
        transform: rotateY(180deg);
      }
    }

    &--front {
      transform: rotateY(0deg);
    }
  }

  &.flip-180-rotate {
    .card-img {
      transition: transform 600ms;
      transform: rotate(180deg) scale(1);
    }
  }

  &--front,
  &--back {
    height: 100%;
    min-height: 245px;
    width: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;

    &.product-image {
      position: relative;
    }
  }

  .is-hidden {
    display: none;
  }

  .card-img {
    display: block;
    margin: auto;
    background-image: repeating-linear-gradient(
      -45deg,
      $martech-gray-5,
      $martech-gray-5 5px,
      $martech-gray-0 5px,
      $martech-gray-0 10px
    );
    transition: transform 200ms;
    height: 100%;
    width: 100%;
    border-radius: $martech-radius-medium;
  }
}
</style>
