<template>
  <base-styles>
    <on-enter-navigate-card :data="data" :is-syndication="isSyndication" :link-path="linkPath" :class="extraCardClass"
      :data-testid="componentID()">
      <base-link v-if="render" :link-url="link || '#'" :url-target="urlTarget" tab-index="-1" @click="onClick(data)">
        <div class="martech-card martech-light martech-shadow martech-card--is-link">
          <div class="martech-card-hero">
            <right-text-overlay v-if="showNewTag && !$route.meta.searchPage" text="New" />
            <div class="martech-card-hero__content">
              <p v-if="showVerticalLink" class="martech-labels martech-white">
                {{ verticalDisplayName }}
              </p>
              <h2 class="title martech-text-lg martech-text-bold martech-barlow martech-one-line"
                :data-testid="componentID('title')">
                {{ displayName }} <companion-paw v-if="companionID" :data-testid="componentID('companion-icon')" />
              </h2>
            </div>
            <figure class="image-wrapper" :style="{ height: heroHeight }">
              <img :class="[{ 'fallback': !image }, { 'yugioh': yugiohImageResize }]" class="hero-image"
                :src="image || fallbackImage" alt="" :style="{ height: heroHeight }"
                @error="errorImageFallback($event)" :data-testid="componentID('hero-image')">
            </figure>
            <div class="card-overlay" />
          </div>
          <div class="body" :style="{ height: bodyHeight }">
            <p class="martech-text-md martech-one-line event" :data-testid="componentID('event-name')">
              {{ eventName }}
            </p>
            <div class="group event-date">
              <div class="player-rank">
                <p v-if="showEventRank" class="martech-text-sm rank" :data-testid="componentID('event-rank')">
                  {{ eventRank }} |
                </p>
                <p v-if="playerName" class="player martech-text-sm" :data-testid="componentID('player')">
                  By <template v-if="isSyndication || !allowLinks">
                    <span class="one-line">{{ playerName }}</span>
                  </template>
                  <base-link v-else
                    tabindex="0"
                    class="martech-text-sm player-link martech-one-line"
                    :link-url="deckPlayerLink"
                    @keyup.enter.stop="navigate(deckPlayerLink)"
                    @click.stop.prevent="navigate(deckPlayerLink)">
                    {{ `${playerName}` }}
                  </base-link>
                </p>
              </div>
              <p v-if="date" class="martech-text-sm deck-date" :data-testid="componentID('date')">
                {{ date }}
              </p>
            </div>
            <div class="group">
              <div class="group">
                <p class="format martech-text-sm martech-text-uppercase martech-text-semibold"
                  :data-testid="componentID('format')">
                  {{ format }}
                </p>
                <div v-if="ink" class="symbols">
                  <lorcana-symbols :color="ink" :size="15" />
                </div>
                <div v-if="color && showMana" class="symbols">
                  <mana-symbols :color="color" :size="13" />
                </div>
              </div>
              <market-price-template new-style class="price" :price="marketPrice" />
            </div>
          </div>
        </div>
      </base-link>
    </on-enter-navigate-card>
  </base-styles>
</template>

<script>
import delve from 'dlv';
import dates from '@/lib/dates';
import showNewTag from '@/mixins/showNewTag';
import BaseLink from '@/components/elements/BaseLink.vue';
import BaseStyles from '@/components/BaseStyles.vue';
import CompanionPaw from '@/components/iconography/CompanionPawIcon.vue';
import MarketPriceTemplate from '@/components/MarketPriceTemplate.vue';
import ManaSymbols from '@/components/iconography/ManaSymbols.vue';
import RightTextOverlay from '@/components/cards/card-parts/RightTextOverlay.vue';
import getVerticalLink from '@/mixins/cards/verticalLink';
import articleTypeTag from '@/mixins/cards/articleTypeTag';
import image from '@/lib/image';
import verts from '@/lib/verticals';
import OnEnterNavigateCard from '@/components/cards/card-parts/OnEnterNavigateCard.vue';
import componentId from '@/mixins/componentId';
import LorcanaSymbols from '@/components/iconography/LorcanaInkColorCost.vue'

export default {
  name: 'card-grid-deck',
  components: {
    CompanionPaw,
    BaseStyles,
    BaseLink,
    MarketPriceTemplate,
    ManaSymbols,
    RightTextOverlay,
    OnEnterNavigateCard,
    LorcanaSymbols,
  },
  mixins: [showNewTag, articleTypeTag, getVerticalLink],
  props: {
    data: {
      type: Object,
      required: true,
      default: null,
    },
    image: {
      type: String,
      required: true,
      default: '',
    },
    link: {
      type: [Object, String],
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    authorUrl: {
      type: Object,
      required: false,
      default: null,
    },
    onClick: {
      type: Function,
      required: false,
      default: (data) => { },
    },
    isSyndication: {
      type: Boolean,
      default: false,
    },
    allowLinks: {
      type: Boolean,
      default: true,
    },
    urlTarget: {
      type: [String, Boolean],
      default: false,
      required: false,
    },
    linkPath: {
      type: String,
      default: '',
    },
    heroHeight: {
      type: String,
      required: false,
      default: '100px',
    },
    bodyHeight: {
      type: String,
      required: false,
      default: undefined,
    },
    extraCardClass: {
      type: String,
      required: false,
      default: '',
    },
    showVerticalLink: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      color: '',
      ink: '',
      companionID: 0,
      contentType: '',
      date: '',
      deckID: null,
      displayName: '',
      eventName: '',
      format: '',
      marketPrice: null,
      vertical: '',
      eventRank: '',
      render: false,
    };
  },
  computed: {
    yugiohImageResize() {
      return this.vertical.toLowerCase() === 'yugioh';
    },
    showEventRank() {
      return !(!this.eventRank || this.eventRank === '' || this.eventRank === '0' || this.eventRank === 0 || this.eventRank === '0-0' || this.eventRank === '0-0-0' || this.eventRank === '-');
    },
    deckPlayerLink() {
      return this.deckSource === 'affiliate'
        ? `/author/${this.playerName.replaceAll(' ', '-').toLowerCase()}/decks`
        : `${this.getVerticalLink}/decks/player/${this.playerName}`;
    },
    fallbackImage() {
      return image.getFallbackImage(this.vertical, 'card');
    },
    verticalDisplayName() {
      return verts.displayName(this.vertical);
    },
    showMana() {
      return this.vertical.toLowerCase() === 'magic';
    },
  },
  created() {
    if (this.data) {
      this.render = this.data || (delve(this.data, 'deckData') || delve(this.data, 'deck', null)) !== null;

      // eventDate in a couple of places -> article date -> deck creation date
      this.date = dates.format(delve(this, 'data.eventDate')
        || delve(this, 'data.deckData.eventDate')
        || delve(this, 'data.deck.eventDate')
        || delve(this, 'data.date')
        || delve(this, 'data.created')
        || delve(this, 'data.deck.created')
        || delve(this, 'data.deckData.created'));

      this.ink = delve(this.data, 'inks.ink') || delve(this.data, 'deckData.inks.ink') || delve(this.data, 'deck.stats.inks.ink', '');
      this.color = delve(this.data, 'color') || delve(this.data, 'deckData.colors') || delve(this.data, 'deck.stats.colors.color', '');
      this.companionID = delve(this.data, 'companionID') || delve(this.data, 'deckData.companionID', 0);
      this.contentType = delve(this.data, 'type') || delve(this.data, 'contentType', 'deck');
      this.deckID = delve(this.data, 'deckID') || delve(this.data, 'id', null);
      this.displayName = delve(this.data, 'deckData.deckName') || delve(this.data, 'deck.name') || delve(this.data, 'name', '');
      this.eventName = delve(this.data, 'deckData.eventName') || delve(this.data, 'deck.eventName') || delve(this.data, 'eventName', '');
      this.eventRank = delve(this.data, 'deckData.eventRank') || delve(this.data, 'deck.eventRank') || delve(this.data, 'eventRank', 0);
      this.format = delve(this.data, 'deckData.format') || delve(this.data, 'deck.format') || delve(this.data, 'format', '');
      this.marketPrice = delve(this.data, 'deckData.marketPrice') || delve(this.data, 'deck.stats.price.marketPrice') || delve(this.data, 'marketPrice', null);
      this.playerName = this.data?.playerName || this.data?.deckData?.playerName || this.data?.deck?.playerName || '';
      this.deckSource = this.data?.deckSource || this.data?.deckData?.deckSource || this.data?.deck?.deckSource || '';
      this.vertical = delve(this.data, 'vertical') || delve(this.data, 'deck.game') || delve(this.data, 'game', '');
    }
  },
  methods: {
    errorImageFallback(event) {
      // Guard against infinite loop
      if (!event?.target || event.target.src === this.fallbackImage) return;
      event.target.src = this.fallbackImage;
    },
    navigate(link) {
      window.location = link;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.martech-base-link) {
  span {
    width: 100%;
  }
}

.martech-card {
  max-width: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  .martech-card-hero {
    position: relative;
    background-color: $martech-black;

    &__content {
      padding: $martech-spacer-2 $martech-spacer-3;
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0;
      width: 100%;

      p,
      h2 {
        color: $martech-white;
        text-shadow: 1px 1px 1px #000000;
      }

      :deep(#paw-icon) {
        margin-left: $martech-spacer-2;
        width: 18px;
        height: 18px;
        color: $martech-white;
      }
    }

    .image-wrapper {
      margin: 0;
      background-color: $martech-black;
    }

    .hero-image {
      position: absolute;
      width: 100%;
      transition: all 0.15s ease-in-out;
      object-fit: cover;
      object-position: top center;

      &.yugioh {
        width: 130%;
        object-position: 25% 25%;
        position: absolute;
        margin-left: -55px;

        &.fallback {
          width: 100%;
          object-position: top center;
        }
      }
    }

    .card-overlay {
      height: 100px;
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    }
  }

  .body {
    padding: $martech-spacer-3;
    color: $martech-text-primary;

    .format {
      line-height: 13px;
    }

    .event {
      line-height: 16px;
      margin-bottom: $martech-spacer-2;
      min-height: 16px;
    }

    .symbols {
      margin-left: $martech-spacer-2;
    }

    .player {
      display: flex;
      align-items: center;

      :deep(.player-link) {
        a {
          color: $martech-text-primary;
          word-wrap: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      span {
        margin-left: $martech-spacer-1;
      }

      &-rank {
        display: flex;
        align-items: center;
        width: 100%;

        .rank {
          margin-right: $martech-spacer-1;
          white-space: nowrap;
        }
      }

      .one-line {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &-link {
        margin-left: $martech-spacer-1;
        line-height: 16px;
        color: $martech-text-primary;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .event-date {
      padding-bottom: $martech-spacer-2;

      p {
        line-height: 16px;
      }
    }

    .deck-date {
      margin-left: $martech-spacer-2;
      white-space: nowrap;
    }
  }

  .group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .price {
    width: auto;
  }
}
</style>
