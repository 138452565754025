<template>
  <on-enter-navigate-card :data="data" :data-testid="componentID('video')">
    <div ref="root" v-if="seriesVideo" class="video-card" tabindex="-1" @click="$emit('clicked')">
      <div class="hero-image">
        <div class="hover-overlay" />
        <video-play-icon standard class="video-icon" />
        <img :src="data.thumbnail || fallbackImage" alt="" @error="errorImageFallback($event)"
          :data-testid="componentID('hero-image')">
      </div>
      <p class="martech-body martech-text-semibold video-label" :class="{ 'dark-mode': siteConfig('global.darkMode') }">
        S{{ data.season }}:E{{ data.position }} {{ data.title }} <template v-if="subscriber">({{ data.length }})</template>
      </p>
      <p class="martech-text-md video-label" :class="{ 'dark-mode': siteConfig('global.darkMode') }">
        {{ data.description }}
      </p>
    </div>
    <base-link v-else :link-url="data.canonicalURL" tab-index="-1" :url-target="urlTarget" :new-window="newWindow">
      <div ref="root" class="video-card" tabindex="-1">
        <div class="hero-image">
          <div class="hover-overlay" />
          <img :src="data.imageURL || fallbackImage" :alt="data?.title + data?.description"
            @error="errorImageFallback($event)" :data-testid="componentID('hero-image')">
          <p class="martech-labels video-age" v-if="data.age">
            {{ data.age.replaceAll('d', ' Days').replaceAll('h', 'Hours') }}
          </p>
        </div>
        <p class="martech-text-md video-label" :class="{ 'dark-mode': siteConfig('global.darkMode') }">
          {{ data.title }}
        </p>
      </div>
    </base-link>
  </on-enter-navigate-card>
</template>

<script setup>
import BaseLink from '@/components/elements/BaseLink.vue';
import OnEnterNavigateCard from '@/components/cards/card-parts/OnEnterNavigateCard.vue';
import useComponentId from '@/use/useComponentId';
import useSiteConfig from '@/use/siteConfig';
import image from '@/lib/image';
import { computed } from 'vue';
import VideoPlayIcon from '@/components/iconography/VideoPlayIcon.vue';

const { componentID } = useComponentId();
const { siteConfig } = useSiteConfig();

defineProps({
  data: {
    type: Object,
    required: true,
  },
  newWindow: {
    type: Boolean,
    default: false,
    required: false,
  },
  urlTarget: {
    type: [String, Boolean],
    default: undefined,
    required: false,
  },
  seriesVideo: {
    type: Boolean,
    default: false,
    required: false,
  },
  subscriber: {
    type: Boolean,
    required: false,
  }
});
const fallbackImage = computed(() => image.getFallbackImage('magic', 'card'));

function errorImageFallback(ev) {
  // Guard against infinite loop
  if (!ev?.target || ev.target.src === get(fallbackImage)) return;
  ev.target.src = get(fallbackImage);
}
</script>

<style lang="scss" scoped>
.video-card {
  position: relative;

  .hero-image {
    position: relative;
    height: 187px;
    margin-bottom: $martech-spacer-3;
    border-radius: $martech-radius-default;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
    }

    .video-icon {
      width: 30px;
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 25%;
      bottom: 25%;
      margin: auto;
      z-index: 1;
    }
  }

  .video-age {
    color: #fff;
    background-color: rgb(0 0 0 / 75%);
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    padding: 0 0.5rem;
    border-radius: 12px;
  }

  .video-label {
    color: $martech-black;

    &.dark-mode {
      color: $martech-white;
    }
  }

  .hover-overlay {
    position: absolute;
    z-index: 25;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.25, 0.1, 0.25, 1);

    &:hover {
      background: $martech-black;
      opacity: 0.5;
    }
  }
}
</style>