<template>
  <on-enter-navigate-card :data="data" :data-testid="componentID(vertical)">
    <base-link :link-url="data.canonicalURL" tab-index="-1">
      <base-styles>
        <div ref="root" class="slim-article-card" tabindex="-1">
            <right-text-overlay v-if="showNewTag" text="New"/>
            <div class="card-image">
                <pro-icon v-if="data?.isPro && allowProIcon"/>
                <img v-if="hasRoot" :src="imageURL(data.imageURL || fallbackImage, { maxDimensions: 'autoxauto', quality: 75, })" alt="" class="card-background" @error="errorImageFallback($event)">
            </div>
            <div class="card-content">
                <p class="martech-labels martech-blue martech-text-uppercase format-label">{{ data.format }}</p>
                <h2 class="martech-sub-heading-sm martech-text-semibold martech-semi-condensed martech-two-lines card-title">{{ data.title }}</h2>
                <p class="martech-text-sm">{{ data.authorName }}</p>
            </div>
        </div>
      </base-styles>
    </base-link>
  </on-enter-navigate-card>
</template>

<script setup>
import { computed } from 'vue';

import BaseLink from '@/components/elements/BaseLink.vue';
import OnEnterNavigateCard from '@/components/cards/card-parts/OnEnterNavigateCard.vue';
import ProIcon from '@/components/cards/card-parts/ProIcon.vue';
import RightTextOverlay from '@/components/cards/card-parts/RightTextOverlay.vue';

import image from '@/lib/image';

import useCardHelpers from '@/use/card/helpers';
import useImageAlter from '@/use/image/alter';
import useComponentId from '@/use/useComponentId';
import { get } from '@vueuse/core';

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({
      title: '',
      imageURL: '',
      date: '',
      canonicalURL: '',
      contentType: '',
      authorURL: '',
      authorName: '',
      authorImageURL: '',
      vertical: '',
      format: '',
      type: 'article',
      teaser: '',
      isPro: false,
    }),
  },
  showOverlay: {
    type: Boolean,
    required: false,
    default: false,
  },
  allowProIcon: {
    type: Boolean,
    default: false,
  },
});

const { componentID } = useComponentId();
const { showNewTag } = useCardHelpers(props);
const { imageURL, hasRoot } = useImageAlter();

const vertical = computed(() => props.data.vertical);
const fallbackImage = computed(() => image.getFallbackImage(props.data.vertical, 'card'));

function errorImageFallback(ev) {
  // Guard against infinite loop
  if (!ev?.target || ev.target.src === get(fallbackImage)) return;
  ev.target.src = get(fallbackImage);
}
</script>

<style lang="scss" scoped>
.slim-article-card {
  display: flex;
  position: relative;

  .card-image {
    width: 120px;
    height: 120px;
    border-radius: $martech-radius-medium;
    overflow: hidden;
    position: relative;

    .card-background {
      width: inherit;
      height: inherit;
      object-fit: cover;
      object-position: top center;
      position: absolute;
    }
  }

  .card-content {
    width: 75%;
    color: $martech-black;
    justify-self: flex-end;
    margin-left: $martech-spacer-2;

    .format-label {
      min-height: 20px;
      margin-bottom: $martech-spacer-1;
    }

    .card-title {
      margin-bottom: $martech-spacer-2;
    }
  }
}
</style>
