<template>
  <base-styles>
    <div class="martech-charts-history" :data-testid="componentID()">
      <div class="martech-charts-content">
        <card-title v-if="displayCardTitle" :card-name="cardName" :set-name="setName"/>
        <div class="martech-charts-header-controls" :class="{ blur: error }">
          <header-default
            :is-detailed="isDetailed"
            variant="Normal"
            :data="error ? errorHeaderData : headerData"
            :time-frame="shouldDisplayVolume ? null : timeFrame"
            :line-position="linePosition"
            :show-price-change="!!isDetailed"
          />
        </div>
        <div ref="chartRef" class="martech-charts-chart" :class="{ blur: error }">
          <line-chart
            v-if="error || labels.length"
            :header="error ? errorHeaderData : headerData"
            :datasets="error ? errorDatasets : datasets"
            :labels="error ? errorLabels : labels"
            :timeFrame="timeFrame"
            :originalDates="originalDates"
            :width="null"
            :height="null"
            :first-valid-data="firstValidData"
            :display-volume="shouldDisplayVolume"
            :data="data"
            @hover="onHover"
            @touchend="onTouchEnd"/>
        </div>
        <error-data v-if="error"/>
      </div>
      <div class="martech-charts-bottom-controls" v-if="allowTimeframe">
        <time-frame-text
          :selected="timeFrame"
          :allow-settings="allowSettings"
          :is-hidden="settingsOpen"
          @change="changeTimeFrame"
          @settings="toggleSettings"/>
      </div>
      <settings-display
        v-if="settingsOpen"
        :available-variants="variants"
        @settings="toggleSettings"
        @change="updateLines"/>
    </div>
  </base-styles>
</template>

<script setup>
import { ref, watch, computed, onMounted, onUnmounted, nextTick } from 'vue';
import { get, set } from '@vueuse/core';
import amplitudeEvent from '@tcgplayer/amplitude';
import LineChart from './charts/Line.vue';
import TimeFrameText from './controls/time-frame/Text.vue';
import HeaderDefault from './controls/header/Default.vue';
import CardTitle from './controls/header/Title.vue';
import SettingsDisplay from './controls/settings/Display.vue';
import ErrorData from './error/Data.vue';
import Api from '@/api/api';
import BaseStyles from '@/components/BaseStyles.vue';

import useComponentId from '@/use/useComponentId';

const { componentID } = useComponentId();

// We require the chart colors from src/scss/variables/_color.scss to be able to
// pass them as the line colors for the chart.  Any changes here should also go there.
const scssVars = {
  chartsPrimaryColor: '#0835DB',
  chartsSecondaryColor: '#008352',
  chartsPrimaryBackgroundColor: '#F2F9FF',
  chartsSecondaryBackgroundColor: '#F2FFFB',
  chartsTertiaryColor: '#D5156D',
  chartsTertiaryBackgroundColor: '#FFF2F7',
  chartsQuaternaryColor: '#F4BF00',
  chartsQuaternaryBackgroundColor: '#FFFFF2',
};

const labelFormatterMonth = new Intl.DateTimeFormat(navigator.location, { month: 'short', year: '2-digit', timeZone: 'UTC', });
const labelFormatterDay = new Intl.DateTimeFormat(navigator.location, { day: 'numeric', month: 'numeric', timeZone: 'UTC', });

const minQuantity = {
  month: 0,
  quarter: 0,
  'semi-annual': 0,
  annual: 0,
};

const emit = defineEmits([ 'timeframe-changed', 'hover', 'data' ]);

const props = defineProps({
  productId: {
    type: [String, Number],
    required: true,
  },
  cardName: {
    type: String,
    required: false,
  },
  setName: {
    type: String,
    required: false,
  },
  allowSkipDays: {
    type: Boolean,
    required: false,
    default: false,
  },
  allowedTypes: {
    type: Array,
    default: () => [ 'marketPrice', ],
  },
  disableSettings: {
    type: Boolean,
    default: false,
  },
  disableTimeframe: {
    type: Boolean,
    default: false,
  },
  displayCardTitle: {
    type: Boolean,
    default: false,
  },
  printing: {
    type: Array,
    default: null,
  },
  condition: {
    type: Array,
    default: null,
  },
  language: {
    type: Array,
    default: null,
  },
  displayVolume: {
    type: Boolean,
    default: false,
  },
  timeFrame: {
    type: String,
    default: 'quarter',
  },
});

const firstValidData = ref(null);
const chartRef = ref(); // was mouseTarget

const labels = ref([]);
const originalDates = ref([]);
const datasets = ref([]);
const timeFrame = ref(props.timeFrame);

const allowSettings = ref(!props.disableSettings);
const allowTimeframe = ref(!props.disableTimeframe);
const settingsOpen = ref(false);
const data = ref([]);
const variants = ref({});
const skippedDays = ref([]);
const headerData = ref({});
const linePosition = ref(-1);
const error = ref(false);
const errorHeaderData = {
  Normal: {
    marketPrice: {
      change: 0,
      checked: true,
      color: scssVars.chartsPrimaryColor,
      name: 'Market Price',
      value: 0,
    },
    averageSalesPrice: {
      change: 0,
      checked: true,
      color: scssVars.chartsSecondaryColor,
      name: 'Foil Market Price',
      value: 0,
    },
  },
};
const errorLabels = [ '1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9', '1/10', '1/11', '1/12', '1/13', '1/14', '1/15',
  '1/16', '1/17', '1/18', '1/19', '1/20', '1/21', '1/22', '1/23', '1/24', '1/25', '1/26', '1/27', '1/28', '1/29', '1/30',
];
const errorDatasets = [
  {
    borderColor: scssVars.chartsPrimaryColor,
    data: [ 0.7, 0.9, 1.41, 1.55, 1.55, 1.55, 1.66, 2.90, 1.87, 1.88, 2.3, 2.1, 1.55, 1.44, 1.33,
      1.32, 1.45, 1.41, 1.55, 1.55, 1.55, 2.66, 2.90, 2.87, 2.88, 3.3, 3.1, 2.55, 3.44, 3.33, ],
  },
  {
    borderColor: scssVars.chartsSecondaryColor,
    data: [ 0.6, 0.8, 1.31, 1.55, 1.45, 1.55, 1.66, 2.60, 1.77, 1.78, 2.2, 1.9, 1.25, 1.14, 1.13,
      1.12, 1.25, 1.21, 1.45, 1.45, 1.45, 2.46, 2.80, 2.67, 2.68, 3.1, 2.9, 2.35, 3.24, 3.13, ],
  },
];

const resetGraphPosition = () => {
  set(linePosition, -1);
  onHover([ get(labels).length - 1 ]);
};

const onTouchEnd = () => {
  resetGraphPosition();
};

const escapeSettings = (event) => {
  if (event.keyCode === 27) {
    set(settingsOpen, false);
  }
};

const getLastWithValue = (data) => {
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i]) return data[i];
  }

  return null;
};

const onHover = (indexes, newLinePosition) => {
  if (newLinePosition) {
    set(linePosition, newLinePosition);
  }

  if (!indexes.length) {
    set(linePosition, -1);
    return;
  }

  const localHeaderData = JSON.parse(JSON.stringify(get(variants)));
  let i = 0;
  // Normal, Foil, etc...
  Object.keys(localHeaderData).forEach((variant) => {
    const localData = localHeaderData[variant];
    // marketPrice, averageSalePrice, etc...
    Object.keys(localData).forEach((type) => {
      const typeData = localData[type];
      // this.datasets will only contain the ones that are checked
      if (typeData.checked) {
        // newLinePosition means we're actively hovering over the chart and should show the one
        // being hovered over.  If not hovering it should find the most current date that has data
        // and display that value.
        const val = newLinePosition
          ? get(datasets)?.[i]?.data?.[indexes[0]] || 'N/A'
          : getLastWithValue(get(datasets)?.[i]?.data) || 'N/A';
        localHeaderData[variant][type].value = val === 'N/A' ? 'N/A' : parseFloat(val);
        localHeaderData[variant][type].date = get(originalDates)?.[indexes[0]] || '';

        const originalNumber = get(datasets)?.[i]?.data?.filter((num) => num > 0)?.shift() || 0;
        const newNumber = get(datasets)?.[i]?.data?.[indexes[0]] || 0;

        localHeaderData[variant][type].change = 0;
        if (originalNumber > 0 && newNumber > 0 && newNumber > originalNumber) {
          localHeaderData[variant][type].change = (newNumber - originalNumber) / originalNumber * 100; // eslint-disable-line
        } else if (originalNumber > 0 && newNumber > 0 && newNumber < originalNumber) {
          localHeaderData[variant][type].change = -((originalNumber - newNumber) / originalNumber * 100); // eslint-disable-line
        }

        i++;
      }
    });
  });

  emit('hover', headerData);

  set(headerData, localHeaderData);
};

const populateLabels = () => {
  const localLabels = [];

  Object.keys(get(data)).forEach((key) => {
    const item = get(data)[key];
    // Currently show all of the dates available and let the graph drop them as needed
    if (!props.allowSkipDays || !get(skippedDays).includes(key)) {
      if (['month', 'quarter'].includes(get(timeFrame))) {
        localLabels.push(labelFormatterDay.format(new Date(`${item.date} UTC`)));
      } else {
        localLabels.push(labelFormatterMonth.format(new Date(item.date)).replace(' ', ' \''));
      }
    }
  });

  return localLabels;
};

const populateOriginalDates = () => {
  const localOriginalDates = [];

  Object.keys(get(data)).forEach((key) => {
    const item = get(data)[key];
    if (!props.allowSkipDays || !get(skippedDays).includes(key)) {
      localOriginalDates.push(item.date);
    }
  });

  return localOriginalDates;
};

const isValidData = (data) => {
  if ((!props.printing || get(selectedPrinting) === data.variant)
    && (!props.condition || get(selectedCondition) === data.condition)) {
    return true;
  }

  return false;
};

const setAvailableVariants = () => {
  const colors = [ scssVars.chartsPrimaryColor, scssVars.chartsSecondaryColor, scssVars.chartsTertiaryColor, scssVars.chartsQuaternaryColor ];
  const colorsBackground = [ scssVars.chartsPrimaryBackgroundColor, scssVars.chartsSecondaryBackgroundColor, scssVars.chartsTertiaryBackgroundColor, scssVars.chartsQuaternaryBackgroundColor ];
  const unique = {};
  get(data).forEach((item) => {
    item.variants.forEach((variant) => {
      if (isValidData(variant) && variant.variant && !unique[variant.variant]) {
        unique[variant.variant] = {};

        let name = 'Market Price';
        if (get(selectedPrinting) && get(selectedCondition)) {
          name = get(selectedCondition)

          if (get(selectedPrinting) !== 'Normal') {
            name += ` ${get(selectedPrinting)} `;
          }

          if (get(selectedLanguage) !== 'English') {
            name += ` ${get(selectedLanguage)}`;
          }
        }

        if (props.allowedTypes.includes('marketPrice')) {
          unique[variant.variant].marketPrice = {
            name,
            checked: true,
            color: colors.shift(),
            colorBackground: colorsBackground.shift(),
          };
        }

        if (props.allowedTypes.includes('averageSalesPrice')) {
          unique[variant.variant].averageSalesPrice = {
            name: 'Average Sold Price',
            checked: false,
            color: colors.shift(),
            colorBackground: colorsBackground.shift(),
          };
        }
      }
    });
  });

  set(variants, unique);
};

const isInvalid = (varData, type) => {
  return varData[type] === null
    || (varData?.quantity || 0) < (minQuantity?.[get(timeFrame)] || 0)
    || parseFloat(varData[type]) <= 0;
};

const shouldDisplayVolume = computed(() => Object.keys(get(variants))?.length === 1 && props.displayVolume);

const populateData = (variant, type) => {
  const localData = [];
  let foundValid = false; // Used to only allow skipping of days after we see the first valid data point
  Object.keys(get(data)).forEach((key) => {
    const item = get(data)[key];
    Object.keys(item.variants).forEach((v) => {
      const varData = item.variants[v];

      if (varData.variant === variant) {
        if (isInvalid(varData, type) && !get(skippedDays).includes(key)) {
          if (foundValid) {
            get(skippedDays).push(key);
          } else {
            varData[type] = null;
          }
        } else {
          foundValid = true;

          const index = parseInt(key);
          if (get(firstValidData) === null || get(firstValidData) > index) {
            set(firstValidData, index);
          }
        }

        localData.push(varData[type]);
      }
    });
  });

  return localData;
};

const pruneDatasets = (localDatasets) => {
  if (props.allowSkipDays) {
    for (let i = get(skippedDays).length - 1; i >= 0; i--) {
      localDatasets.forEach((set) => {
        set.data.splice(get(skippedDays)[i], 1);
      });
    }
  }

  return localDatasets;
};

// Update datasets & labels to render the new chart
const updateChart = async () => {
  set(labels, []); // This will hide the chart via v-if so it will properly re-render
  set(skippedDays, []);
  const localDatasets = [];
  set(error, false);
  set(firstValidData, null);

  Object.keys(get(variants)).forEach((variant) => {
    const variantData = get(variants)[variant];

    props.allowedTypes.forEach((type) => {
      if (variantData[type] && variantData[type].checked) {
        localDatasets.push({
          borderColor: variantData[type].color,
          type: 'line',
          data: populateData(variant, type),
          pointHoverBackgroundColor: variantData[type].color,
          pointHoverBorderColor: 'rgb(255,255,255)',
          yAxisID: 'y',
          pointHoverRadius: get(shouldDisplayVolume) ? 5 : 0,
        });

        if (get(shouldDisplayVolume)) {
          localDatasets.push({
            borderColor: 'rgba(170, 225, 255, 0.5)',
            backgroundColor: 'rgba(170, 225, 255, 0.5)',
            hoverBackgroundColor: '#91D7FF',
            type: 'bar',
            data: populateData(variant, 'quantity'),
            yAxisID: 'y1',
            barPercentage: 0.95,
            categoryPercentage: 0.95,
          });
        }
      }
    });
  });

  if (!props.disableSettings) {
    set(allowSettings, Object.entries(get(variants)).length > 0);
  }

  // We are pruning after populating because we need to go through all of the data before we
  // know what can be removed
  await nextTick();

  set(labels, populateLabels());
  set(originalDates, populateOriginalDates());
  set(datasets, pruneDatasets(localDatasets));

  if (!get(datasets).length) {
    set(error, true);
  }

  let datasetCount = get(datasets).length;
  let foundOne = false;
  for (let i = 0; i < get(datasets).length; i++) {
    for (let j = 0; j < get(datasets)[i].data.length; j++) {
      // See if there's at least 1 without 0 or null
      if (parseFloat(get(datasets)[i].data[j])) {
        foundOne = true;
      }
    }

    // If all results for all datasets are null or 0 then we have nothing to show
    if (!foundOne || get(datasets)[i].data.length < 2) {
      datasetCount--;
    }
  }

  // At least one of the datasets must have data
  if (datasetCount < 1) {
    set(error, true);
  }
};

const selectedPrinting = ref(null);
const selectedCondition = ref(null);
const selectedLanguage = ref(null);
const resultMatch = ref();

const isDetailed = computed(() => !!(props.printing || props.condition || props.language));

const convertDetailed = (results) => {
  const converted = [];
  const printings = Array.isArray(props.printing) ? [...props.printing] : [];
  const conditions = Array.isArray(props.condition) ? [...props.condition] : [];
  const languages = Array.isArray(props.language) ? [...props.language] : [];
  printings.reverse();
  conditions.reverse();
  languages.reverse();

  // 1. Find a match within printings/conditions/languages
  // 2. Fall back to partial matches Language > printing > condition
  // Must only match within a users selections, so if a user has selected
  // Foil & Near Mint or Lightly Played it must have some combination of those
  // things but since Language is not set it can be anything
  const matches = [
    {i: null, totalScore: 0},
    {i: null, totalScore: 0},
    {i: null, totalScore: 0},
    {i: null, totalScore: 0},
    {i: null, totalScore: 0},
    {i: null, totalScore: 0},
  ];

  const conditionOrder = ['Near Mint', 'Lightly Played', 'Moderately Played', 'Heavily Played', 'Damaged'];

  results.forEach((variant, i) => {
    const langScore = languages.indexOf(variant.language) + 1;
    const printScore = printings.indexOf(variant.variant) + 1;
    const condScore = conditions.indexOf(variant.condition) + 1;
    const totalScore = langScore + printScore + condScore;

    // Find the best match based on users selected preferences
    if (langScore && printScore && condScore && totalScore > matches[0]?.totalScore) {
      matches[0] = { i, totalScore, };
    } else if (
      !conditions.length
        && langScore && printScore && totalScore > matches[1]?.totalScore) {
      matches[1] = { i, totalScore, };
    } else if (
      !printings.length
        && langScore && condScore && totalScore > matches[2]?.totalScore) {
      matches[2] = { i, totalScore, };
    } else if (
      !languages.length
        && printScore && condScore && totalScore > matches[3]?.totalScore) {
      matches[3] = { i, totalScore, };
    } else if (
      !languages.length && !conditions.length
        && printScore && totalScore > matches[4]?.totalScore) {
      matches[4] = { i, totalScore, };
    } else if (
      !languages.length && !printings.length
        && condScore && totalScore > matches[5]?.totalScore) {
      matches[5] = { i, totalScore, };
    }
  });

  let match = matches.filter((it) => it.i !== null).shift()?.i;

  set(resultMatch, match);

  if (match === undefined) return [];

  set(selectedPrinting, results[match].variant);
  set(selectedCondition, results[match].condition);
  set(selectedLanguage, results[match].language);

  // Find first valid data for price trend calculation
  let firstMarketPrice;
  for (let i = results[match].buckets.length - 1; i >= 0; i--) {
    if (results[match].buckets[i].marketPrice > 0) {
      firstMarketPrice = parseFloat(results[match].buckets[i].marketPrice);
      break;
    }
  }

  results[match].buckets.forEach((bucket) => {
    converted.push({
      date: bucket.bucketStartDate,
      variants: [
        {
          marketPrice: bucket.marketPrice,
          quantity: bucket.quantitySold,
          variant: results[match].variant,
          condition: results[match].condition,
          language: results[match].language,
          lowSalePrice: bucket.lowSalePrice,
          highSalePrice: bucket.highSalePrice,
          marketPriceTrend: firstMarketPrice ? (parseFloat(bucket.marketPrice) / firstMarketPrice - 1).toFixed(4) * 100 : null,
        },
      ],
    });
  });

  return converted;
};

const fillMissing = (data) => {
  if (data.length < 2) return data;

  const d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();

  const timePeriodLength = get(timeFrame) === 'month' ? 3 : 7;

  let filled = [];

  let toDate = `${year}-${month}-${day}`;

  data.forEach((it) => {
    const variant = it.variants[0]?.variant;
    const dt = new Date(it.date);
    let diff = Math.floor(((new Date(toDate)) - dt) / 1000 / 86400);

    if (diff % timePeriodLength) {
      diff -= diff % timePeriodLength;
    }

    if (diff > timePeriodLength) {
      for (let i = diff; i > 0; i -= timePeriodLength) {
        const phDate = new Date(dt.valueOf() + (i * 86400 * 1000));

        const placeholder = {
          date: `${phDate.getFullYear()}-${phDate.getMonth() + 1}-${phDate.getDate()}`,
          variants: [
            {
              highSalePrice: '0',
              lowSalePrice: '0',
              marketPrice: null,
              marktePriceTrend: -100,
              quantity: null,
              condition: '',
              language: '',
              variant,
            },
          ]
        }

        filled.push(placeholder);
      }
    }

    filled.push(it);

    toDate = it.date;
  });

  return filled;
};

const getData = async (productId, keepAvailableVariants) => {
  let res;

  if (!productId) {
    return;
  }

  if (get(isDetailed)) {
    try { res = await Api.getDetailedPriceHistory(productId, get(timeFrame)); } catch (_) {}
  } else {
    try { res = await Api.getPriceHistory(productId, get(timeFrame)); } catch (_) {}
  }

  let originalData = res?.data?.result;

  if (!Array.isArray(originalData)) originalData = [];
  let resultData = structuredClone(originalData);

  // Figure out which buckets to use
  if (get(isDetailed) && originalData.length) {
    resultData = fillMissing(convertDetailed(resultData));
  }

  // Must come after convertDetailed for resultMatch to be defined
  emit('data', get(resultMatch) >= 0 ? originalData[get(resultMatch)] : {});

  set(data, resultData.slice().reverse());

  // Make sure variants in variantOrder come first.  This affects the order of display
  const variantOrder = [ 'Normal', 'Unlimited', ];
  for (let i = 0; i < get(data).length; i++) {
    if (variantOrder.includes(get(data)?.[i]?.variants?.[0]?.variant || '')) continue;

    for (let j = 0; j < get(data)[i].variants.length; j++) {
      if (variantOrder.includes(get(data)?.[i]?.variants?.[j]?.variant || '')) {
        const item = get(data)[i].variants.splice(j, 1);
        get(data)[i].variants.unshift(item[0]);
        break;
      }
    }
  }

  if (!keepAvailableVariants) {
    setAvailableVariants();
  }

  await updateChart();
};

// Update the lines that are shown as checkboxes are checked/unchecked
const updateLines = async (variant, type) => {
  if (typeof get(variants)?.[variant]?.[type]?.checked !== 'undefined') {
    amplitudeEvent('charts', 'pricePointChanged', {
      variant,
      type,
      checked: !get(variants)[variant][type].checked,
    });

    get(variants)[variant][type].checked = !get(variants)[variant][type].checked;
  }

  await updateChart();
  onHover([ get(labels).length - 1 ]);
};

const changeTimeFrame = async (newTimeFrame) => {
  if (newTimeFrame === get(timeFrame)) return;

  amplitudeEvent('charts', 'timeFrameClicked', {
    newTimeFrame,
  });

  set(timeFrame, newTimeFrame)
  const skipVariants = Object.keys(get(variants)).length !== 0;
  await getData(props.productId, skipVariants);

  emit('timeframe-changed', newTimeFrame);

  resetGraphPosition();
};

const toggleSettings = () => {
  set(settingsOpen, !get(settingsOpen));
};

watch(
  () => [props.productId, props.printing, props.condition, props.language],
  async () => {
    await getData(props.productId);
    resetGraphPosition();
  },
  {
    deep: true,
  }
);

onMounted(async () => {
  await getData(props.productId);
  onHover([ get(labels).length - 1 ]);

  document.addEventListener('keyup', escapeSettings);

  await nextTick();

  if (!get(chartRef)) return;
  document.addEventListener('keyup', escapeSettings);
  get(chartRef).addEventListener('mouseleave', resetGraphPosition);
});

onUnmounted(() => {
  if (!get(chartRef)) return;
  document.removeEventListener('keyup', escapeSettings);
  get(chartRef).removeEventListener('mouseleave', resetGraphPosition);
});
</script>

<style lang="scss" scoped>
.martech-charts-history {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid $martech-border;

  .martech-charts-content {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: $martech-spacer-3;
  }

  .martech-charts-chart {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;

    &.blur {
      filter: blur(2px);
    }
  }
  .martech-charts-header-controls {
    flex: 0 1 auto;
    margin-bottom: $martech-spacer-2;

    &.blur {
      filter: blur(2px);
    }
  }
  .martech-charts-bottom-controls {
    height: 32px;
    margin: $martech-spacer-2 $martech-spacer-3 $martech-spacer-3;
  }
}
</style>
